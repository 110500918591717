import React from "react";

import connect from "../../../../../../../i18n/connect";
import { PlanBoxContent } from "../planBoxContent";

const BlackFridayPremiumPlanBoxContent = ({ plan, translate }) => {
  return (
    <PlanBoxContent
      mode={"dark"}
      discount={translate(
        `lp_black-friday_plan-table_${plan}_premium-button_discount`
      )}
      link={{
        href: translate(`lp_plan-table_${plan}_premium-button_href`),
        text: translate("lp_plan-table_premium-button_text")
      }}
      title={translate("lp_plan-table_premium-button_title")}
      price={translate(`lp_plan-table_${plan}_premium-button_price`)}
      description={translate(
        `lp_plan-table_${plan}_premium-button_description`
      )}
    />
  );
};

export default connect()(BlackFridayPremiumPlanBoxContent);
