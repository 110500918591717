import React from "react";

import connect from "../../../../../../../i18n/connect";

const BlackWeekTopBar = ({ translate }) => {
  return (
    <aside className={"bg-yellow px-10 py-3"}>
      <div
        className={
          "max-w-11xl.1 mx-auto flex justify-between items-center text-black-3 font-medium"
        }
      >
        <span className={"text-xs"}>
          {translate("lp_black-week_top-bar_text-1")}
        </span>
        <span
          className={"text-sm"}
          dangerouslySetInnerHTML={{
            __html: translate("lp_black-week_top-bar_text-2")
          }}
        />
        <span className={"text-xs"}>
          {translate("lp_black-week_top-bar_text-3")}
        </span>
      </div>
    </aside>
  );
};

export default connect()(BlackWeekTopBar);
