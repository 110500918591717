import classNames from "classnames";
import React from "react";

import connect from "../../../../../../i18n/connect";
import Link from "../../../../common/SquaberLink";
import useIsDesktop from "../../../hooks/useIsDesktop";
import FooterSquaberLogo from "../assets/squaber-logo.svg";
import YouTubeLogoDark from "../assets/youtube-168_svgrepo.com_1.svg";
import { FooterLinks } from "./footerLinks";

export const Footer = ({ translate }) => {
  const isDesktop = useIsDesktop();

  return (
    <footer className={"max-w-11xl mx-auto"}>
      <div
        className={classNames("max-w-11xl mx-auto", {
          "px-10": isDesktop,
          "px-5": !isDesktop
        })}
      >
        <div
          className={classNames("mb-10", {
            "flex justify-between flex-wrap text-base": isDesktop,
            "text-xs": !isDesktop
          })}
        >
          <div>
            <img
              src={FooterSquaberLogo}
              alt={"Squaber logo"}
              height={48}
              className={classNames({ "mb-8": isDesktop, "mb-6": !isDesktop })}
            />
            <p
              className={classNames("max-w-81 opacity-65 mb-10", {
                "text-sm": isDesktop,
                "text-xs": !isDesktop
              })}
            >
              {translate("lp_footer_paragraph")}
            </p>
            {isDesktop ? (
              <Link
                className={"block mb-10"}
                target={"_blank"}
                to={translate("lp_footer_youtube-channel-href")}
              >
                <img
                  src={YouTubeLogoDark}
                  alt={"Youtube logo"}
                  width={"40"}
                  height={"40"}
                />
              </Link>
            ) : null}
          </div>
          <nav className={classNames({ "flex gap-32 items-start": isDesktop })}>
            <FooterLinks
              className={
                "mb-0 pl-0 list-none grid grid-cols-2-max-content gap-6"
              }
              navIndex={1}
              linksCount={6}
            />
            <div className={classNames({ "flex gap-32": isDesktop })}>
              {isDesktop ? (
                <div
                  className={
                    "-my-4 h-full hr-vertical hr-vertical_yellow h-auto"
                  }
                  style={{ height: "auto" }}
                />
              ) : (
                <div className={"hr-horizontal my-10 hr-horizontal_yellow"} />
              )}
              <FooterLinks
                className={"mb-0 pl-0 list-none grid gap-6"}
                navIndex={2}
                linksCount={5}
              />
            </div>
          </nav>
        </div>
      </div>
      <div
        className={classNames("py-6 border-t flex gap-8 justify-between", {
          "mx-10 text-sm": isDesktop,
          "px-5 text-xs": !isDesktop
        })}
      >
        <span
          className={"opacity-65 text-white"}
        >{`© ${new Date().getFullYear()} Squaber.`}</span>
        <div className={"flex gap-4"}>
          <Link
            to={translate("lp_copyright_link-1_href")}
            className={
              "opacity-65 text-white no-underline hover:text-yellow focus:text-yellow"
            }
          >
            {translate("lp_copyright_link-1_text")}
          </Link>
          <Link
            to={translate("lp_copyright_link-2_href")}
            className={
              "opacity-65 text-white no-underline hover:text-yellow focus:text-yellow"
            }
          >
            {translate("lp_copyright_link-2_text")}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default connect()(Footer);
