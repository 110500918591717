import classNames from "classnames";
import React from "react";

import connect from "../../../../../../../i18n/connect";
import Link from "../../../../../common/SquaberLink";
import useIsDesktop from "../../../../hooks/useIsDesktop";
import BlackFriday from "../../assets/black_friday.png";
import CountdownTimer from "../countdownTimer";

const HeaderContentBlackFriday = ({ translate }) => {
  const isDesktop = useIsDesktop();

  return (
    <div className={classNames("max-w-full", { "w-2xl.2": isDesktop })}>
      <strong
        className={classNames("highlight-1 block", {
          "mb-3": isDesktop,
          "mb-2": !isDesktop
        })}
      >
        {translate("lp_header_subtitle")}
      </strong>
      <img
        src={BlackFriday}
        alt={translate("lp_header_black-friday-alt")}
        width={614}
        className={"mb-6 max-w-full"}
      />
      <p
        className={classNames("opacity-85", {
          "mb-10 text-base": !isDesktop,
          "mb-12 text-xl": isDesktop
        })}
        dangerouslySetInnerHTML={{
          __html: translate("lp_header_black-friday-paragraph")
        }}
      />

      <div
        className={classNames("flex box-1 w-full gap-4", {
          "mb-10 flex-wrap-reverse gap-4 justify-center": !isDesktop,
          "max-w-2xl.3 justify-between": isDesktop
        })}
      >
        <Link
          to={translate("lp_create-account-button_href")}
          className={classNames(
            "flex justify-center items-center button button_full-width",
            { "max-w-71": isDesktop }
          )}
        >
          {translate("lp_create-account-button_text")}
        </Link>
        <CountdownTimer
          className={isDesktop ? "min-w-max" : undefined}
          targetTime={Number(translate("lp_black-friday_time"))}
        />
      </div>
    </div>
  );
};

export default connect()(HeaderContentBlackFriday);
