import classNames from "classnames";
import React from "react";

const CountdownTimerBox = ({ label, number, size = "default" }) => {
  return (
    <div
      className={classNames(
        "flex flex-col bg-white-500 gap-1 text-center text-black-2 justify-center max-w-full",
        {
          "w-18 pt-2 pb-2.5 rounded-1xl": size === "big",
          "w-17 pt-1.5 pb-2 rounded-lg": size === "default"
        }
      )}
    >
      <strong className={"text-2xl.2 font-bold"}>
        {addLeadingZero(number)}
      </strong>
      <span className={"text-xs"}>{label}</span>
    </div>
  );
};

const addLeadingZero = number => {
  return number < 10 && number > -10 ? `0${number}` : number;
};

export default CountdownTimerBox;
