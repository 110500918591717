import { Linking } from "react-native";
import * as WebBrowser from "expo-web-browser";
import { WebBrowserOpenOptions } from "expo-web-browser";
import Url from "url-parse";

const skipOnHosts = ["edukacja.squaber.com"];

const openBrowserAsync = (
  url: string,
  browserParams?: WebBrowserOpenOptions
) => {
  const { host } = new Url(url);

  if (skipOnHosts.includes(host)) {
    return Linking.openURL(url);
  } else {
    return WebBrowser.openBrowserAsync(url, {
      createTask: false,
      ...(browserParams ?? {})
    });
  }
};

export default openBrowserAsync;
