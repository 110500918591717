import Polyglot from "node-polyglot";
import { connect as reduxConnect } from "react-redux";

import {
  getFetchCnt,
  getLocale,
  getTranslations
} from "../redux/modules/translations/selector";

let translatedFunctions = {};
let translationExists = null;
let fetchCntSemapthore = null;

export const translationExistsBase = (state, locale, translation) => {
  const phrases = getTranslations(state)[locale];

  return typeof phrases?.[translation] !== "undefined";
};

export const connect = (mapProps, mapDispatch, mapMerge) => {
  const translateMapProps = (state, props) => {
    const locale = getLocale(state);

    if (fetchCntSemapthore !== getFetchCnt(state)) {
      translatedFunctions = {};
      translationExists = null;
      fetchCntSemapthore = getFetchCnt(state);
    }

    if (!translatedFunctions[locale]) {
      const polyglot = new Polyglot({
        locale,
        phrases: getTranslations(state)[locale],
        allowMissing: true
      });

      translatedFunctions[locale] = polyglot.t.bind(polyglot);
    }

    if (translationExists === null) {
      translationExists = function(translation) {
        return translationExistsBase(state, locale, translation);
      };
    }

    const translateProps = {
      locale: locale,
      translate: translatedFunctions[locale],
      translationExists
    };

    const mappedProps = mapProps ? mapProps(state, props) : {};
    return Object.assign(translateProps, mappedProps);
  };

  return reduxConnect(translateMapProps, mapDispatch, mapMerge);
};

export default connect;
