import classNames from "classnames";
import React from "react";

import Link from "../../../../common/SquaberLink";
import useIsDesktop from "../../../hooks/useIsDesktop";

export const PlanBoxContent = ({
  title,
  price,
  description,
  link,
  mode,
  discount
}) => {
  const isDesktop = useIsDesktop();

  return (
    <>
      <div className={"flex gap-4 justify-between mb-1"}>
        <h3
          className={classNames({
            "text-2xl.1": isDesktop,
            "text-2xl": !isDesktop
          })}
        >
          {title}
        </h3>
        <strong
          className={classNames("font-bold whitespace-nowrap", {
            "text-3xl.1": isDesktop,
            "text-2xl.1": !isDesktop
          })}
        >
          {price}
        </strong>
      </div>
      <div className={"flex gap-2 justify-between"}>
        <p
          className={"text-sm"}
          dangerouslySetInnerHTML={{ __html: discount ?? "" }}
        ></p>
        <p className={"text-right text-xs opacity-50"}>{description}</p>
      </div>
      <Link
        to={link.href}
        className={classNames("button-1 block w-full", {
          "button-1 block w-full bg-black-2 text-white hover:bg-black focus:bg-black":
            mode === "dark"
        })}
      >
        {link.text}
      </Link>
    </>
  );
};
