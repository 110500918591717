import React from "react";

import connect from "../../../../../../i18n/connect";
import Link from "../../../../common/SquaberLink";
import NavSquaberLogo from "../assets/squaber-logo.svg";

const NavDesktop = ({ translate }) => {
  return (
    <div className={"absolute w-full px-10"}>
      <nav
        className={
          "max-w-11xl.1 mx-auto pt-8 pb-2 flex items-center justify-between"
        }
      >
        <Link to={translate("lp_squaber-logo_href")}>
          <img
            src={NavSquaberLogo}
            alt={translate("lp_squaber-logo_alt")}
            height={48}
          />
        </Link>
        <ul className={"text-sm flex gap-6 mb-0 pl-0 list-none font-medium"}>
          <li>
            <Link
              className={
                "br py-2 pr-6 text-white no-underline hover:text-yellow focus:text-yellow"
              }
              to={translate("lp_top-nav_link-1_href")}
            >
              {translate("lp_top-nav_link-1_text")}
            </Link>
          </li>
          <li>
            <Link
              to={translate("lp_top-nav_link-2_href")}
              className={
                "br py-2 pr-6 text-white no-underline hover:text-yellow focus:text-yellow"
              }
            >
              {translate("lp_top-nav_link-2_text")}
            </Link>
          </li>
          <li>
            <Link
              to={translate("lp_top-nav_link-3_href")}
              className={
                "br py-2 pr-6 text-white no-underline hover:text-yellow focus:text-yellow"
              }
            >
              {translate("lp_top-nav_link-3_text")}
            </Link>
          </li>
          <li>
            <Link
              to={translate("lp_top-nav_link-4_href")}
              className={
                "br py-2 pr-6 text-white no-underline hover:text-yellow focus:text-yellow"
              }
            >
              {translate("lp_top-nav_link-4_text")}
            </Link>
          </li>
          <li>
            <Link
              to={translate("lp_top-nav_link-5_href")}
              className={
                "py-2 pr-6 text-white no-underline hover:text-yellow focus:text-yellow"
              }
            >
              {translate("lp_top-nav_link-5_text")}
            </Link>
          </li>
          <li>
            <Link
              to={translate("lp_login-button_href")}
              className={"button-3 button_blue"}
            >
              {translate("lp_login-button_text")}
            </Link>
          </li>
          <li>
            <Link
              to={translate("lp_create-account-button_href")}
              className={"button-3"}
            >
              {translate("lp_create-account-button_text")}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default connect()(NavDesktop);
