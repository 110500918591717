import "../styles/default.scss";

import classNames from "classnames";
import moment from "moment-mini";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { compose } from "redux";
import SweetScroll from "sweet-scroll";

import { UrlProvider } from "../../../../../api/UrlProvider";
import commentExlamationIcon from "../../../../../assets/images/comment-exclamation.svg";
import Price from "../../../../../common/Price";
import config from "../../../../../config";
import withAttention from "../../../../../hocs/withAttention";
import withFirstAnalysis from "../../../../../hocs/withFirstAnalysis";
import withQuoteData from "../../../../../hocs/withQuoteData";
import getStockMessages from "../../../../../lib/getStockMessages";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import { shouldShowAdminComment } from "../../../../../lib/shouldShowAdminComment";
import { updateAttentionStateLocal } from "../../../../../lib/updateAttentionStateLocal";
import ChangePercent from "../../../common/ChangePercent";
import Label from "../../../common/Label";
import SquaberButton from "../../SquaberButton";
import SquaberLink from "../../SquaberLink";
import StockAlertStatus from "../../StockAlertStatus";
import StockPageTags from "../../StockPageTags";

const StockPageHeader = ({
  authToken,
  stock,
  translate,
  locale,
  quoteData,
  emptyQuoteData,
  signal,
  userHasAccess,
  title,
  attentionState,
  updateAttentionState,
  tags,
  stockId,
  firstAnalysis,
  stockData
}) => {
  const [scroller, setScroller] = useState();
  let signalType;
  const { attention_updated } = stock;

  const hasAttention = getStockMessages(stockData, translate).length;

  useEffect(() => {
    setScroller(
      SweetScroll.create({
        duration: 500,
        easing: "easeOutExpo"
      })
    );
  }, [authToken, locale, stock]);

  if (signal && signal.type === "sell") {
    signalType = "negative";
  } else if (signal && signal.type === "buy") {
    signalType = "positive";
  } else {
    signalType = "neutral";
  }
  const showAdminComment = shouldShowAdminComment(
    attentionState,
    attention_updated
  );
  const scrollToSection = sectionId => {
    let sectionNav = document.querySelector(
      `#${sectionId} .Collapsible__trigger`
    );

    if (!sectionNav.classList.contains("is-open")) {
      sectionNav.click();
    }

    scroller.to(`#${sectionId}`, {
      offset: -90
    });
  };

  function interestingSituationSection() {
    return (
      <SquaberButton
        buttonStyle={false}
        onClick={() => scrollToSection("section-current-signal")}
        className="interesting-situation-link"
      >
        <div className="interesting-situation">
          <div>
            <span className="description upper">
              {translate("Interesting situation")}
            </span>
            {signal?.updated ? (
              <span className="update-time">
                {translate("update")}{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: moment(signal.updated)
                      .utc()
                      .add(2, "hours")
                      .format("DD.MM.YYYY")
                  }}
                />
              </span>
            ) : null}
          </div>
          {(function(signal) {
            if (signal && signal.type === "sell") {
              return <Label error>{translate("Warning")}</Label>;
            } else if (signal && signal.type === "buy") {
              return <Label success>{translate("Bargain")}</Label>;
            }
          })(signal)}
        </div>
      </SquaberButton>
    );
  }

  function teamCommentarySection(analysis) {
    return (
      <div className="team-commentary">
        <div>
          <span className="description">
            {translate("Squaber Team analysis")}
          </span>
          {locale === "pl" && analysis.updated ? (
            <span className="update-time">
              {translate("from the")}{" "}
              <span
                dangerouslySetInnerHTML={{
                  __html: moment(analysis.updated)
                    .utc()
                    .add(2, "hours")
                    .format("DD.MM.YYYY")
                }}
              />
            </span>
          ) : null}
        </div>
        <SquaberLink
          to={UrlProvider.getUrl("fe.singleAnalysisPage", {
            locale,
            id: analysis.id
          })}
          className="read"
        >
          {translate("Read")}
        </SquaberLink>
      </div>
    );
  }

  function submitForAnalysisSection() {
    return (
      <div className="submit-for-analysis">
        <span className="description">
          {translate("Send a request for analysis")}
        </span>
        <SquaberLink
          className="link"
          target="_blank"
          to={translate("submit_for_analysis_link")}
        >
          {translate("Send")}
        </SquaberLink>
      </div>
    );
  }

  const { market, ticker } = stock;

  return (
    <div className="stock-page-header">
      <div className="header-stock-data-section">
        <div className="additional-details">
          <h1 className="full-name">
            {title ? title : `${stock.name} (${stock.ticker})`}
            {!showAdminComment && hasAttention ? (
              <span
                className={"admin-comment-icon"}
                onClick={() =>
                  updateAttentionStateLocal(
                    market,
                    ticker,
                    updateAttentionState,
                    attentionState
                  )
                }
              >
                <img
                  alt={"Comment exclamation icon"}
                  src={commentExlamationIcon}
                />
              </span>
            ) : null}
          </h1>
        </div>
        <div className={"stock-data-wrapper"}>
          <div className="stock-basic-data">
            <div className="stock-basic-data-summary">
              <span className="ticker">{stock.ticker}</span>
              {!emptyQuoteData ? (
                <div className="stock-status">
                  <div className="change">
                    <ChangePercent change={quoteData.change} />
                  </div>
                  <div className="price">
                    <Price value={quoteData.close} currency={stock.currency} />
                  </div>
                </div>
              ) : null}
            </div>
            {config.vendor !== "expalerts" && !emptyQuoteData ? (
              <div className="update-time">
                {translate("Quotation of")}:{" "}
                <span
                  dangerouslySetInnerHTML={{
                    __html: moment(quoteData.datetime_utc)
                      .utc()
                      .add(2, "hours")
                      .format("DD.MM.YYYY")
                  }}
                />
              </div>
            ) : null}
          </div>
          <StockPageTags tags={tags || []} stockId={stockId} />

          <div className={"mobile-tags-wrapper"}>
            <div
              className="tags-button"
              onClick={() => {
                showModal("StockTagsListModal", { stockId: stock.id });
              }}
            >
              <i className="fas fa-tag" />
            </div>
            <StockAlertStatus stockId={stock.id} />
          </div>
        </div>
      </div>
      {userHasAccess ? (
        <span
          className={classNames({
            "stock-financial-situation": true,
            [signalType]: true
          })}
        >
          {(function(signal, firstAnalysis) {
            if (signal) {
              return interestingSituationSection();
            } else if (firstAnalysis?.[0]) {
              return teamCommentarySection(firstAnalysis[0]);
            } else {
              return submitForAnalysisSection();
            }
          })(signal, firstAnalysis)}
        </span>
      ) : null}
    </div>
  );
};

StockPageHeader.propTypes = {
  stock: PropTypes.shape({
    ticker: PropTypes.string,
    currency: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.number
  }).isRequired,
  user_stock: PropTypes.object,
  userHasAccess: PropTypes.bool,
  signal: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  quote: PropTypes.object,
  firstAnalysis: PropTypes.array
};

export default compose(
  withQuoteData(),
  withAttention,
  withFirstAnalysis
)(StockPageHeader);
