import classNames from "classnames";
import React from "react";

import useIsDesktop from "../../../hooks/useIsDesktop";
import Placeholder from "../assets/frame_187.png";

export const BoxReview = ({
  reviewText,
  name,
  company,
  profileImage = Placeholder,
  image
}) => {
  const isDesktop = useIsDesktop();

  return (
    <>
      {image ? (
        <img
          className={classNames({ "mb-10": isDesktop, "mb-6": !isDesktop })}
          src={image.src}
          alt={image.alt}
          width={image.width}
          height={image.height}
        />
      ) : null}
      <p
        className={classNames("opacity-75", {
          "text-lg": isDesktop,
          "text-xs mb-10": !isDesktop
        })}
        dangerouslySetInnerHTML={{ __html: reviewText }}
      />
      <div
        className={classNames("mt-auto flex justify-end", {
          "text-sm gap-4": isDesktop,
          "text-xs gap-1.5": !isDesktop
        })}
      >
        <div className={"flex items-center gap-1.5"}>
          <span>{name}</span>
          <span className={"text-yellow"}>{company}</span>
        </div>
        <img
          src={profileImage}
          width={isDesktop ? "32" : "24"}
          height={isDesktop ? "32" : "24"}
          alt={"Profile image"}
          className={"rounded overflow-hidden"}
        />
      </div>
    </>
  );
};
