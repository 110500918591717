import classNames from "classnames";
import React from "react";

import LandingTypography from "../../../../components/LandingTypography";

const BlackFridayLandingTypography = ({ children, className }) => {
  return (
    <LandingTypography
      className={classNames(className, "landing-typography_black")}
    >
      {children}
    </LandingTypography>
  );
};

export default BlackFridayLandingTypography;
