import classNames from "classnames";
import React from "react";

import connect from "../../../../../../i18n/connect";
import Link from "../../../../common/SquaberLink";
import useIsDesktop from "../../../hooks/useIsDesktop";
import YouTubeLogo from "../assets/youtube-168_svgrepo.com.svg";
import { Box } from "./box";

const VideoShortcut = ({
  className,
  videoTitle,
  videoHref,
  channelImage,
  subscriptionCount,
  channelImageAlt,
  translate
}) => {
  const isDesktop = useIsDesktop();

  return (
    <Link
      to={videoHref}
      className={classNames("block focus:opacity-80", className)}
    >
      <Box className={"px-4 py-3 flex text-white justify-between gap-6"}>
        <div className={"flex items-center gap-3"}>
          <img src={YouTubeLogo} alt={""} width={"24"} height={"24"} />
          <strong className={"font-medium text-xs"}>{videoTitle}</strong>
        </div>
        <div className={"flex gap-3 items-center"}>
          <div className={"flex gap flex-col text-right"}>
            <strong
              className={classNames({
                "text-sm": isDesktop,
                "text-xs font-semibold": !isDesktop
              })}
            >
              {subscriptionCount}
            </strong>
            <span
              className={classNames({
                "text-xs": isDesktop,
                "text-xs.1": !isDesktop
              })}
            >
              {translate("lp_subscription-count")}
            </span>
          </div>
          <img
            className={"rounder-full overflow-hidden"}
            src={channelImage}
            alt={channelImageAlt}
            width={"32"}
            height={"32"}
          />
        </div>
      </Box>
    </Link>
  );
};

export default connect()(VideoShortcut);
