import classNames from "classnames";
import React, { useEffect, useState } from "react";

import connect from "../../../../../../i18n/connect";
import CountdownTimerBox from "./countdownTimerBox";

const CountdownTimer = ({
  translate,
  targetTime,
  size = "default",
  className
}) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft({ targetTime }));

  useEffect(() => {
    const interval = setInterval(() => {
      const calculatedTimeLeft = calculateTimeLeft({ targetTime });

      if (
        calculatedTimeLeft.days === 0 &&
        calculatedTimeLeft.hours === 0 &&
        calculatedTimeLeft.minutes === 0 &&
        calculatedTimeLeft.seconds === 0
      ) {
        clearInterval(interval);
      }

      setTimeLeft(calculateTimeLeft({ targetTime }));
    }, 1000);
    return () => clearInterval(interval);
  }, [targetTime]);

  return (
    <div
      className={classNames(className, "grid grid-cols-4", {
        "gap-2": size === "default",
        "gap-2.5": size === "big"
      })}
    >
      <CountdownTimerBox
        size={size}
        number={timeLeft.days}
        label={translate(
          isNumberPlural(timeLeft.days)
            ? "lp_countdown-timer_days"
            : "lp_countdown-timer_day"
        )}
      />
      <CountdownTimerBox
        size={size}
        number={timeLeft.hours}
        label={translate(
          isNumberPlural(timeLeft.hours)
            ? "lp_countdown-timer_hours"
            : "lp_countdown-timer_hour"
        )}
      />
      <CountdownTimerBox
        size={size}
        number={timeLeft.minutes}
        label={translate(
          isNumberPlural(timeLeft.minutes)
            ? "lp_countdown-timer_minutes"
            : "lp_countdown-timer_minute"
        )}
      />
      <CountdownTimerBox
        size={size}
        number={timeLeft.seconds}
        label={translate(
          isNumberPlural(timeLeft.seconds)
            ? "lp_countdown-timer_seconds"
            : "lp_countdown-timer_second"
        )}
      />
    </div>
  );
};

const isNumberPlural = number => {
  return number !== 1 && number !== -1;
};

const calculateTimeLeft = ({ targetTime }) => {
  const difference = targetTime - new Date().getTime();
  let timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };
  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60)
    };
  }
  return timeLeft;
};

export default connect()(CountdownTimer);
