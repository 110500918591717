import "../styles/default.scss";

import classNames from "classnames";
import React from "react";

const LandingTypography = ({ children, className }) => {
  return (
    <div className={classNames("landing-typography", className)}>
      {children}
    </div>
  );
};

export default LandingTypography;
