import classNames from "classnames";
import React from "react";

import connect from "../../../../../../i18n/connect";
import Link from "../../../../common/SquaberLink";
import useIsDesktop from "../../../hooks/useIsDesktop";
import YouTubeLogo from "../assets/youtube-168_svgrepo.com.svg";

export const VideoBox = ({
  videoTitle,
  videoImageAlt,
  chanelImage,
  videoHref,
  videoImage,
  chanelHref,
  channelImageAlt,
  subscriptionCount,
  translate
}) => {
  const isDesktop = useIsDesktop();

  return (
    <div className={"video-box"}>
      <div className={"flex justify-between gap-6 mb-4"}>
        <div className={"flex gap-3 items-center"}>
          <img
            src={YouTubeLogo}
            alt={"YouTube Logo"}
            width={"24"}
            height={"24"}
          />
          <strong
            className={classNames("text-xs font-semibold", {
              "text-sm": isDesktop,
              "text-xs": !isDesktop
            })}
          >
            {videoTitle}
          </strong>
        </div>
        <div className={"flex gap-3 items-center"}>
          <div className={"flex gap flex-col text-right"}>
            <strong
              className={classNames({
                "text-sm": isDesktop,
                "text-xs font-semibold": !isDesktop
              })}
            >
              {subscriptionCount}
            </strong>
            <span
              className={classNames({
                "text-xs": isDesktop,
                "text-xs.1": !isDesktop
              })}
            >
              {translate("lp_subscription-count")}
            </span>
          </div>
          <img
            className={"rounder-full overflow-hidden"}
            src={chanelImage}
            alt={channelImageAlt}
            width={"32"}
            height={"32"}
          />
        </div>
      </div>
      <Link
        to={videoHref}
        className={classNames("block", {
          "mb-8": isDesktop,
          "mb-6": !isDesktop
        })}
      >
        <img
          className={"overflow-hidden rounded-2xl w-full"}
          src={videoImage}
          alt={videoImageAlt}
        />
      </Link>
      <Link to={chanelHref} className={"button-2 max-w-72 block mx-auto"}>
        {translate("lp_video-box_button-text")}
      </Link>
    </div>
  );
};

export default connect()(VideoBox);
