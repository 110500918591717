import { useLocation } from "react-router";

import BlackFridayLandingTypography from "../pages/Homepage/components/blackFriday/blackFridayLandingTypography";
import BlackFridayPlanBanner from "../pages/Homepage/components/blackFriday/blackFridayPlanBanner";
import BlackFridayPremiumPlanBoxContent from "../pages/Homepage/components/blackFriday/blackFridayPremiumPlanBoxContent";
import BlackFridayToggleSelectPlan from "../pages/Homepage/components/blackFriday/blackFridayToggleSelectPlan";
import BlackFridayTopBar from "../pages/Homepage/components/blackFriday/blackFridayTopBar";
import HeaderContentBlackFriday from "../pages/Homepage/components/blackFriday/headerContentBlackFriday";
import BlackWeekPlanBanner from "../pages/Homepage/components/blackWeek/blackWeekPlanBanner";
import BlackWeekPremiumPlanBoxContent from "../pages/Homepage/components/blackWeek/blackWeekPremiumPlanBoxContent";
import BlackWeekToggleSelectPlan from "../pages/Homepage/components/blackWeek/blackWeekToggleSelectPlan";
import BlackWeekTopBar from "../pages/Homepage/components/blackWeek/blackWeekTopBar";
import HeaderContentBlackWeek from "../pages/Homepage/components/blackWeek/headerContentBlackWeek";
import HeaderContent from "../pages/Homepage/components/headerContent";
import LandingTypography from "../pages/Homepage/components/landingTypography";
import PremiumPlanBoxContent from "../pages/Homepage/components/premiumPlanBoxContent";
import ToggleSelectPlan from "../pages/Homepage/components/toggleSelectPlan";
import HeaderImageDesktopDefault from "../pages/Homepage/assets/macbook_pro_1.png";
import HeaderImageDesktopBlackFriday from "../pages/Homepage/assets/laptop_black_friday.png";
import HeaderImageDesktopBlackWeekend from "../pages/Homepage/assets/laptop-blackweekend.png";

const useTimedChanges = ({ translate }) => {
  // TODO: Remove search before production release
  const { search } = useLocation();

  const now = new Date().getTime();

  const blackFriday = [1732820400000, 1732921200000];
  const blackWeek = [1732921201000, 1733180400000];

  const isBlackFriday =
    (blackFriday[0] <= now && now <= blackFriday[1]) || search === "?v=2";
  const isBlackWeek =
    (blackWeek[0] <= now && now <= blackWeek[1]) || search === "?v=3";

  if (isBlackFriday) {
    return {
      LandingTypography: BlackFridayLandingTypography,
      PlanBanner: BlackFridayPlanBanner,
      HeaderContent: HeaderContentBlackFriday,
      ToggleSelectPlan: BlackFridayToggleSelectPlan,
      TopBar: BlackFridayTopBar,
      PremiumPlanBoxContent: BlackFridayPremiumPlanBoxContent,
      HeaderImageDesktop: HeaderImageDesktopBlackFriday
    };
  }

  if (isBlackWeek) {
    return {
      LandingTypography: BlackFridayLandingTypography,
      PlanBanner: BlackWeekPlanBanner,
      HeaderContent: HeaderContentBlackWeek,
      ToggleSelectPlan: BlackWeekToggleSelectPlan,
      TopBar: BlackWeekTopBar,
      PremiumPlanBoxContent: BlackWeekPremiumPlanBoxContent,
      HeaderImageDesktop: HeaderImageDesktopBlackWeekend
    };
  }

  return {
    LandingTypography: LandingTypography,
    PlanBanner: undefined,
    HeaderContent: HeaderContent,
    ToggleSelectPlan: ToggleSelectPlan,
    TopBar: undefined,
    PremiumPlanBoxContent: PremiumPlanBoxContent,
    HeaderImageDesktop: HeaderImageDesktopDefault
  };
};

export default useTimedChanges;
