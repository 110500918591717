import classNames from "classnames";
import React from "react";

import connect from "../../../../../../i18n/connect";
import useIsDesktop from "../../../hooks/useIsDesktop";
import useTimedChanges from "../../../hooks/useTimedChanges";
import HeaderImageMobile from "../assets/iphone-mockup-3_1.png";
import HeaderAside from "./headerAside";

const Header = ({ translate }) => {
  const isDesktop = useIsDesktop();
  const { HeaderImageDesktop, HeaderContent } = useTimedChanges({ translate });

  return (
    <header
      style={{ paddingTop: isDesktop ? "112px" : "96px" }}
      className={classNames("overflow-hidden bg-header text-white", {
        "px-5": !isDesktop,
        "px-10": isDesktop
      })}
    >
      <div
        className={classNames("max-w-10xl.1 mx-auto", {
          "mt-8": !isDesktop,
          "flex items-center mt-24": isDesktop
        })}
      >
        <HeaderContent />
        <img
          src={isDesktop ? HeaderImageDesktop : HeaderImageMobile}
          alt={translate("lp_homepage_header-image")}
          height={isDesktop ? 713 : undefined}
          width={isDesktop ? undefined : 330}
          className={classNames("header-image block", {
            "mx-auto": !isDesktop
          })}
        />
      </div>
      {isDesktop ? <HeaderAside /> : null}
    </header>
  );
};

export default connect()(Header);
