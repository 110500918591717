import React from "react";

import connect from "../../../../../../i18n/connect";
const HeaderAside = ({ translate }) => {
  return (
    <aside className={"header-aside max-w-11xl.1 mx-auto"}>
      <div
        className={"header-aside__text opacity-80"}
        dangerouslySetInnerHTML={{
          __html: translate("lp_header_aside-1")
        }}
      />
      <svg
        className={"header-aside__reverse-border"}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16 16.0002H0C8.58826 15.4276 15.4274 8.58827 16 0V16.0002Z" />
      </svg>
      <div className={"header-aside__separator"} />
      <svg
        className={"header-aside__reverse-border"}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 16.0002H16C7.41174 15.4276 0.572551 8.58827 0 0V16.0002Z" />
      </svg>
      <div
        className={"header-aside__text opacity-80 text-right"}
        dangerouslySetInnerHTML={{
          __html: translate("lp_header_aside-2")
        }}
      />
    </aside>
  );
};

export default connect()(HeaderAside);
