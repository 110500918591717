import classNames from "classnames";
import React from "react";

import DefaultLandingTypography from "../../../components/LandingTypography";

const LandingTypography = ({ children, className }) => {
  return (
    <DefaultLandingTypography
      className={classNames(className, "landing-typography_dark")}
    >
      {children}
    </DefaultLandingTypography>
  );
};

export default LandingTypography;
