import classNames from "classnames";
import React from "react";

import connect from "../../../../../../i18n/connect";
import useIsDesktop from "../../../hooks/useIsDesktop";
import BartoszSliwka from "../assets/bartosz-sliwa.png";
import GrzegorzKoczwara from "../assets/grzegorz-koczwara.png";
import AliorBank from "../assets/image_30.png";
import PiotrLussa from "../assets/piotr-lussa.png";
import WojciechCywinski from "../assets/wojciech-cywinski.png";
import { Box } from "./box";
import { BoxContent } from "./boxContent";
import { BoxReview } from "./boxReview";

export const Reviews = ({ translate }) => {
  const isDesktop = useIsDesktop();

  return (
    <>
      <Box>
        <BoxContent className={"h-full flex flex-col"}>
          <BoxReview
            name={translate("lp_section-4_review-1_name")}
            company={translate("lp_section-4_review-1_company")}
            reviewText={translate("lp_section-4_review-1_review-text")}
            profileImage={WojciechCywinski}
          />
        </BoxContent>
      </Box>
      <Box
        className={classNames("col-start-1 row-start-1 row-end-3", {
          "col-start-2 row-start-1 row-end-3 border-yellow": isDesktop,
          "col-start-1 row-start-1 row-end-3": !isDesktop
        })}
      >
        <BoxContent className={"h-full flex flex-col"}>
          <BoxReview
            image={{
              src: AliorBank,
              alt: "Alior Bank",
              width: "78",
              height: "39"
            }}
            name={translate("lp_section-4_review-2_name")}
            company={translate("lp_section-4_review-2_company")}
            profileImage={GrzegorzKoczwara}
            reviewText={translate("lp_section-4_review-2_review-text")}
          />
        </BoxContent>
      </Box>
      <Box>
        <BoxContent className={"h-full flex flex-col"}>
          <BoxReview
            name={translate("lp_section-4_review-3_name")}
            company={translate("lp_section-4_review-3_company")}
            reviewText={translate("lp_section-4_review-3_review-text")}
            profileImage={PiotrLussa}
          />
        </BoxContent>
      </Box>
      <Box>
        <BoxContent className={"h-full flex flex-col"}>
          <BoxReview
            name={translate("lp_section-4_review-4_name")}
            company={translate("lp_section-4_review-4_company")}
            reviewText={translate("lp_section-4_review-4_review-text")}
            profileImage={BartoszSliwka}
          />
        </BoxContent>
      </Box>
      <Box>
        <BoxContent className={"h-full flex flex-col"}>
          <BoxReview
            name={translate("lp_section-4_review-5_name")}
            company={translate("lp_section-4_review-5_company")}
            reviewText={translate("lp_section-4_review-5_review-text")}
          />
        </BoxContent>
      </Box>
    </>
  );
};

export default connect()(Reviews);
