import React from "react";

import connect from "../../../../../../i18n/connect";
import useIsDesktop from "../../../hooks/useIsDesktop";
import { ToggleSelect } from "./toggleSelect";

const ToggleSelectPlan = ({ translate, plan, setPlan }) => {
  const isDesktop = useIsDesktop();

  return (
    <ToggleSelect
      options={[
        {
          label: translate("lp_plan-table_yearly_toggle-select_label"),
          value: "yearly",
          tooltipTop: !isDesktop
            ? translate("lp_plan-table_yearly_toggle-select_tooltip")
            : undefined,
          tooltipBottom: isDesktop
            ? translate("lp_plan-table_yearly_toggle-select_tooltip")
            : undefined
        },
        {
          label: translate("lp_plan-table_monthly_toggle-select_label"),
          value: "monthly"
        }
      ]}
      value={plan}
      onChange={setPlan}
    />
  );
};

export default connect()(ToggleSelectPlan);
