import classNames from "classnames";
import React from "react";

export const ToggleSelect = ({ options, value, onChange }) => {
  return (
    <div className={"toggle-select"}>
      {options.map(
        ({ label, value: optionValue, tooltipTop, tooltipBottom }) => {
          const isActive = optionValue === value;

          return (
            <div key={optionValue} className={"toggle-select__option"}>
              <button
                className={classNames("toggle-select__button", {
                  "toggle-select__button_active": isActive
                })}
                onClick={() => onChange(optionValue)}
              >
                {label}
              </button>
              {tooltipTop ? (
                <span
                  className={
                    "text-xs text-center text-white w-full gap-1 toggle-select__tooltip toggle-select__tooltip_top font-medium opacity-90"
                  }
                  dangerouslySetInnerHTML={{ __html: tooltipTop }}
                />
              ) : null}
              {tooltipBottom ? (
                <span
                  className={
                    "text-xs text-center text-white w-full gap-1 toggle-select__tooltip toggle-select__tooltip_bottom font-medium opacity-90"
                  }
                  dangerouslySetInnerHTML={{ __html: tooltipBottom }}
                />
              ) : null}
            </div>
          );
        }
      )}
    </div>
  );
};
