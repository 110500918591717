const menuItems = {
  personal: {
    name: "personal",
    label: "Personal",
    icon: "fal fa-user",
    nativeIcon: "user",
    group: "account"
  },
  payments: {
    name: "payments",
    label: "Payments",
    icon: "fal fa-coins",
    nativeIcon: "coins",
    group: "account"
  },
  partnership: {
    name: "partnership",
    label: "Partners",
    icon: "fal fa-gift-card",
    nativeIcon: "gift-card",
    requiresPremium: true,
    group: "account"
  },
  affiliate: {
    name: "affiliate",
    label: "Affiliate program",
    icon: "fal fa-link",
    nativeIcon: "link",
    requiresPremium: false,
    group: "account"
  },
  notifications: {
    name: "notifications",
    label: "Notifications",
    icon: "fal fa-bell",
    nativeIcon: "bell",
    group: "settings"
  },
  markets: {
    name: "markets",
    label: "Markets",
    icon: "fal fa-analytics",
    nativeIcon: "analytics",
    group: "settings"
  },
  tags: {
    name: "tags",
    label: "Tags settings panel",
    icon: "fal fa-tag",
    nativeIcon: "tag",
    requiresPremium: true,
    group: "settings"
  }
};

module.exports = {
  recommendationsToFetch: 5,
  intercomAppID: "hf7bcrz9",
  trackEvents: false,
  googleAnalyticsId: "UA-46882716-1",
  oldAccessLevelStorageKey: "OLD_ACCESS_LEVEL",
  paymentDetailsStorageKey: "PAYMENT_DETAILS",
  introductionModalStorageKey: "SHOW_INTRODUCTION_MODAL",
  shouldRedirectToNativeAfterPaymentStorageKey:
    "SHOULD_REDIRECT_TO_NATIVE_AFTER_PAYMENT",
  recaptchaKey: "6LcIhuwSAAAAAC3RSaqpiD4j8NHaPKRG7OQdT704",
  squaberCommentDescriptionMaxLength: 300,
  userIdCookieName: "squaber_auth_user_id",
  authTokenCookieName: "squaber_auth_token",
  buildNumber: 6,
  socketIoKeys: {
    notifications: "browser_notification",
    portfolios: "portfolio_exchange"
  },
  webSocketPort: "",
  nativeAppScheme: "investio.squaber.staging://",
  amplitude: {
    apiKey: "5104277dcf4c7d4822fcb983ac4406eb",
    trackRegistration: true,
    trackRegistrationConfirmation: true,
    trackRevenue: true
  },
  appStoreUrl:
    "https://apps.apple.com/pl/app/squaber-gie%C5%82dowe-alerty/id1473168185?l=pl",
  googlePlayUrl:
    "https://play.google.com/store/apps/details?id=com.apk.investio.squaber",
  numberOfDaysUntilAffiliateCookieExpiry: 60,
  pathnamesWithoutOverlies: ["/payment-details/", "/l/"],
  maxDaysWithoutQuotations: 7,
  revenueCatApiKey: {
    ios: "appl_TNMkdnEzbjSVzlkGjrFEUNwYiCp",
    android: "goog_RoLCLxxUyDhMPsnRuwzvGrgxZgu"
  },
  staticFilesHostName: "squaber-statics-stage.s3.amazonaws.com",
  firebase: {
    apiKey:
      "AAAAM9wTFK0:APA91bETjQMG58ETrGdkb3ODFGIPgdlTzr9FhVg_o3O2U1mrJpCV1uzZYSzACD2_Q_vWU2tO-YjrgL7f4gJEot0uXwe_wk3e0wdnU2tQk9j2p7ooBM6b22JP843zvyfB7Rge2FiKPiXS",
    authDomain: "investio-backup.firebaseapp.com",
    databaseURL: "https://squaber-staging-aws.firebaseio.com/",
    storageBucket: "investio-backup.appspot.com",
    appId: {
      ios: "1:222735570093:ios:3cfcab4505acd03c4f6224",
      android: "1:222735570093:android:eab657a003707bc4"
    },
    projectId: "investio-backup",
    messagingSenderId: "222735570093",
    measurementId: "G-189639239",
    nativeSdkApiKey: {
      ios: "AIzaSyALMtHWVomF7INsrfPpIwArtiujrdQUeoM",
      android: "AIzaSyDDR8KXvnkCEAP-4CVMiSnB85QazmPTuaE"
    }
  },
  availableCurrencies: ["PLN"],
  settingsGroups: [
    {
      name: "account",
      label: "Account"
    },
    {
      name: "settings",
      label: "Settings"
    }
  ],
  nativeMenu: {
    userCategories: [
      menuItems.personal,
      menuItems.payments,
      menuItems.partnership,
      menuItems.affiliate
    ],
    settingsCategories: [
      menuItems.notifications,
      menuItems.markets,
      menuItems.tags
    ]
  },
  settingsCategories: [
    menuItems.personal,
    menuItems.payments,
    menuItems.partnership,
    menuItems.affiliate,
    menuItems.notifications,
    menuItems.markets,
    menuItems.tags
  ],
  trackSMEvents: true,
  googleAuthWebClientId:
    "222735570093-h87resini5b5ep55jgm54bsr060gkmf7.apps.googleusercontent.com"
};
