import "../styles/default.scss";

import React from "react";
import { withRouter } from "react-router";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withHomeLandingPage from "../../../../../hocs/withHomeLandingPage";
import withUserData from "../../../../../hocs/withUserData";
import eventEmitter from "../../../../../lib/react-redux-modal-provider/eventEmitter";
import SquaberLogoLoader from "../../../common/SquaberLogoLoader/SquaberLogoLoader";
import Homepage from "../../../landingPage/pages/Homepage";
import { Helmet } from "react-helmet";

@withUserData
@withHomeLandingPage
@withRouter
class HomePageRedirect extends React.Component {
  componentDidMount(): void {
    const { userData, getHomeLandingPage, history, locale } = this.props;

    if (userData) {
      history.replace(
        UrlProvider.getUrl("fe.desktopPage", {
          locale
        }) + window.location.search
      );
    } else {
      eventEmitter.emit("isHttpRequestsLoading", true);
      getHomeLandingPage();
    }
  }

  render() {
    const { userData, homeLandingPageState } = this.props;

    if (!userData && homeLandingPageState.data) {
      return (
        <>
          <Helmet>
            <meta
              name="description"
              content="Zarabiaj na giełdzie ze Squaberem. Jako jedyni w Polsce publikujemy statystyki rekomendacji na żywo. Zapewniamy najlepszą obsługę użytkowników. Z naszą aplikacją Twoje inwestycje staną się skuteczniejsze. Zobacz jak działamy."
            />
          </Helmet>
          <Homepage />
        </>
      );
    }

    return <SquaberLogoLoader />;
  }
}

export default HomePageRedirect;
