import React from "react";

import connect from "../../../../../../i18n/connect";
import useIsDesktop from "../../../hooks/useIsDesktop";
import useTimedChanges from "../../../hooks/useTimedChanges";
import { PlanBox } from "./planBox";
import { PlanBoxContent } from "./planBoxContent";
import PlanOptions from "./planOptions";

const PlanTable = ({ plan, setPlan, translate }) => {
  const isDesktop = useIsDesktop();
  const { PremiumPlanBoxContent, ToggleSelectPlan } = useTimedChanges({
    translate
  });

  const premiumPlanBoxContent = <PremiumPlanBoxContent plan={plan} />;
  const basicPlanBoxContent = (
    <PlanBoxContent
      mode={"light"}
      link={{
        href: translate("lp_plan-table_freemium-button_href"),
        text: translate("lp_plan-table_freemium-button_text")
      }}
      title={translate("lp_plan-table_freemium-button_title")}
      price={translate("lp_plan-table_freemium-button_price")}
      description={translate("lp_plan-table_freemium-button_description")}
    />
  );

  const toggleSelectPlan = <ToggleSelectPlan plan={plan} setPlan={setPlan} />;

  if (isDesktop)
    return (
      <div className={"plan-table"}>
        <table className={"plan-table__table"}>
          <thead>
            <tr>
              <th className={"align-top"}>
                <div className={"flex gap-8 items-center"}>
                  <label className={"text-2xl.1 font-semibold mb-0"}>
                    {translate("lp_plan-table_toggle-select_desktop-label")}
                  </label>
                  {toggleSelectPlan}
                </div>
              </th>
              <th className={"p-6"}>{basicPlanBoxContent}</th>
              <th className={"p-6 bg-yellow"}>{premiumPlanBoxContent}</th>
            </tr>
          </thead>
          <tbody>
            <PlanOptions count={20} />
          </tbody>
        </table>
      </div>
    );

  return (
    <>
      <div className={"flex gap-4 items-center mb-8"}>
        <label className={"text-xs font-semibold mb-0"}>
          {translate("lp_plan-table_toggle-select_mobile-label")}
        </label>
        {toggleSelectPlan}
      </div>
      <div className={"plan-table mb-6"}>
        <table className={"plan-table__table"}>
          <thead>
            <tr>
              <th className={"text-xs text-black-1 w-2/4"}>
                {translate("lp_plan-table_mobile-column-1_heading")}
              </th>
              <th className={"text-center text-base font-medium w-1/4"}>
                {translate("lp_plan-table_freemium-button_title")}
                <br />
                <strong className={"font-semibold"}>
                  {translate("lp_plan-table_freemium-button_price")}
                </strong>
              </th>
              <th
                className={"text-center text-base font-medium bg-yellow w-1/4"}
              >
                {translate("lp_plan-table_premium-button_title")}
                <br />
                <strong className={"font-semibold"}>
                  {translate(`lp_plan-table_${plan}_premium-button_price`)}
                </strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <PlanOptions count={20} />
          </tbody>
        </table>
      </div>
      <PlanBox className={"mb-4"} mode={"dark"}>
        {premiumPlanBoxContent}
      </PlanBox>
      <PlanBox mode={"light"}>{basicPlanBoxContent}</PlanBox>
    </>
  );
};

export default connect()(PlanTable);
