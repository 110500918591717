import classNames from "classnames";
import React from "react";

import connect from "../../../../../../i18n/connect";
import Link from "../../../../common/SquaberLink";
import useIsDesktop from "../../../hooks/useIsDesktop";

const HeaderContent = ({ translate }) => {
  const isDesktop = useIsDesktop();

  return (
    <div>
      <strong
        className={classNames("highlight-1 block", {
          "mb-4": !isDesktop,
          "mb-6": isDesktop
        })}
      >
        {translate("lp_header_subtitle")}
      </strong>
      <h1
        className={classNames({
          "mb-6": !isDesktop,
          "mb-10 max-w-2xl.2": isDesktop
        })}
      >
        {translate("lp_header_title")}
      </h1>
      <p
        className={classNames("opacity-85", {
          "mb-10": !isDesktop,
          "mb-12 max-w-2xl.1": isDesktop
        })}
        dangerouslySetInnerHTML={{
          __html: translate("lp_header_paragraph")
        }}
      />
      <Link
        to={translate("lp_create-account-button_href")}
        className={classNames("button button_full-width", {
          "mb-10": !isDesktop,
          "max-w-72": isDesktop
        })}
      >
        {translate("lp_create-account-button_text")}
      </Link>
    </div>
  );
};

export default connect()(HeaderContent);
