import classNames from "classnames";
import React from "react";

import connect from "../../../../../../../i18n/connect";
import useIsDesktop from "../../../../hooks/useIsDesktop";
import BlackWeekOneLine from "../../assets/black-week-text-horizontal-min.png";
import BlackWeek from "../../assets/black-week-text-min.png";
import BlackWeekendOneLine from "../../assets/black-weekend-text-horizontal-min.png";
import BlackWeekend from "../../assets/black-weekend-text-min.png";
import CountdownTimer from "../countdownTimer";

const BlackWeekPlanBanner = ({ translate, locale }) => {
  const isDesktop = useIsDesktop();

  return (
    <div
      className={classNames(
        "rounded-1xl flex bg-black-3 gap-6 max-w-7xl mx-auto",
        {
          "px-12 py-3 mb-11 justify-between items-center": isDesktop,
          "pt-4 px-5 flex-col items-start mb-19 pb-6": !isDesktop
        }
      )}
    >
      <div>
        <img
          className={classNames("max-w-full", {
            "mb-1.5": isDesktop,
            "mb-4": !isDesktop
          })}
          src={
            isDesktop
              ? locale === "pl"
                ? BlackWeekendOneLine
                : BlackWeekOneLine
              : locale === "pl"
              ? BlackWeekend
              : BlackWeek
          }
          width={isDesktop ? 423 : 250}
          alt={translate("lp_black-week_section-3_banner_image-alt")}
        />
        <p
          className={classNames("mb-0 opacity-85", {
            "text-lg": isDesktop,
            "text-base": !isDesktop
          })}
          dangerouslySetInnerHTML={{
            __html: translate("lp_black-week_section-3_banner_paragraph")
          }}
        />
      </div>
      <CountdownTimer
        targetTime={Number(translate("lp_black-week_time"))}
        size={isDesktop ? "big" : "default"}
      />
    </div>
  );
};

export default connect()(BlackWeekPlanBanner);
